// Stepper.js
import React, { useState, useEffect, useRef } from 'react';
import '../../assets/styles/steppers/Stepper.css';

function Stepper({ contents }) {
  const [currentContent, setCurrentContent] = useState(contents[0]);
  const [pageCounter, setPageCounter] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const intervalRef = useRef();

  useEffect(() => {
    if (isActive)
      intervalRef.current = setInterval(() => {
        setPageCounter((prevCounter) => {
          const count =
            prevCounter !== contents.length - 1 ? prevCounter + 1 : 0;
          setCurrentContent(() => contents[count]);
          return count;
        });
      }, 14000);
    else clearInterval(intervalRef.current);

    return () => clearInterval(intervalRef.current);
  }, [isActive]);

  const handleIndicatorClick = (count) => {
    setPageCounter(() => count);
    setCurrentContent(() => contents[count]);
  };

  const toggleIntervalActiveState = () => setIsActive(!isActive);

  let StepperIndicators = [];

  for (let i = 0; i < contents.length; i++) {
    StepperIndicators.push(
      <span
        className={`dot ${pageCounter === i ? 'closed' : 'open'}`}
        key={i}
        onClick={() => handleIndicatorClick(i)}
      ></span>
    );
  }

  return (
    <div className="stepper-container">
      <div
        className="content-container"
        onMouseOver={toggleIntervalActiveState}
        onMouseLeave={toggleIntervalActiveState}
      >
        {currentContent}
      </div>

      <div className="stepper-indicators">{StepperIndicators}</div>
    </div>
  );
}

export default Stepper;
