// NotificationCard.js
import React, { useEffect, useState } from 'react';
import Stepper from '../steppers/stepper';
import '../../assets/styles/cards/NotificationCard.css';
import MailIcon from '@mui/icons-material/Mail';
import { useSelector } from 'react-redux';

function NotificationCard({ style, items }) {
  //const messages = useSelector((state) => state.main.messages);
  const message = useSelector((state) => state.main.message);
  //const [notification, setNotification] = useState(false);

  // useEffect(() => {
  //   if (newMessage?.id != messages[messages.length - 1]?.id) {
  //     console.log('newMessage', newMessage);
  //     setNotification(true);
  //   }
  // }, [newMessage]);

  const card = (title, description, message, actions, type, priority) => (
    <div className="notification-card-container">
      {/* <div className="icon-container">
        {type === 'info' && priority === 'normal' && <MailIcon />}
      </div> */}
      <div className="content">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
      {actions?.length > 0 && (
        <div className="action">
          {actions.map((action, index) => (
            <button key={index}>{action.title}</button>
          ))}
        </div>
      )}
    </div>
  );

  const GetCard = () => {

    if (message) {
      return card(message.title, message.description, message.message, message.actions, message.type, message.priority);
    }

    // const openMessages = messages.filter((message) => message.status === 'open');
    // if (openMessages.length > 1) {
    //   return (
    //     <Stepper
    //       contents={openMessages.map((message) =>
    //         card(message.title, message.description, message.message, message.actions, message.type, message.priority)
    //       )}
    //     />
    //   );
    // }

    // if (openMessages.length === 1) {
    //   const message = openMessages[0];
    //   return card(message.title, message.description, message.message, message.actions, message.type, message.priority);
    // }

    // if (items.length > 1)
    //   return (
    //     <Stepper
    //       contents={items.map((item) =>
    //         card(item.title, item.description, item.message, item.actions, item.type, item.priority)
    //       )}
    //     />
    //   );

    // if (items.length === 0) return <div></div>;
    // const item = items[0];
    // return card(item.title, item.description, item.message, item.actions, item.type, item.priority);
  };

  return (
    <React.Fragment>
      {
        message && (
          <div className="card notification-card" style={{ ...style }}>
            <GetCard />
          </div>)
      }
    </React.Fragment>
  );
}

export default NotificationCard;