// BannerCard.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../../assets/styles/cards/BannerCard.css';
import { sendMessage } from '../Actions';

function BannerCard({ style, title, description, backgroundImage, actions }) {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const handleAction = (action) => {
        if (!action) return;

        if (action.action == "chat") {
            let message = { "type": "user", "message": action.payload?.message, "thread_id": null, "route": action.payload?.route }
            //setIsModalOpen(true);
            dispatch(sendMessage(message));
        }
    };

    return (
        <div className="card banner-card" style={{ ...style, backgroundImage: `url(${backgroundImage})` }}>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="actions-buttons">
                {actions.map((action, index) => (
                    <button key={index} onClick={() => { handleAction(action) }}>{action.title}</button>
                ))}
            </div>
        </div>
    );
}

export default BannerCard;