// ActionCard.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../../assets/styles/cards/ActionCard.css';
import { sendMessage } from '../Actions';
import Modal from '../Modal'; // Importeer uw Modal component
import Chatbot from '../Chatbot'; // Importeer uw Chatbot component

function ActionCard({ style, actions }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAction = (action) => {
    if (!action) return;

    if (action.action == "chat") {
      let message = { "type": "user", "message": action.payload?.message, "thread_id": null, "route": action.payload?.route }
      //setIsModalOpen(true);
      dispatch(sendMessage(message));
    }
  };

  return (
    <React.Fragment>
      {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Chatbot modal={true} />
      </Modal> */}
      <div className="card action-card">
        <div className="action-buttons-container">
          {actions.map((action, index) => (
            <button key={index} onClick={() => { handleAction(action) }}>{action.title}</button>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ActionCard;