// TaskCard.js
import React from 'react';

function TaskCard({ style, title, description, action }) {
  return (
    <div className="card task-card" style={{ ...style }}>
      <h2>{title}</h2>
      <p>{description}</p>
      <button>{action}</button>
    </div>
  );
}

export default TaskCard;