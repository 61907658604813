// NewsCard.js
import React from 'react';
import Stepper from '../steppers/stepper';
import '../../assets/styles/cards/NewsCard.css';

function NewsCard({ style, items }) {
  if (!items) return <div></div>;

  const card = (image, title, description, action) => (
    <div className="news-card-container">
      <div className="image-container">
        <img src={image} />
      </div>
      <div className="content">
        <h2>{title}</h2>
        <p>
          {
            description /* needs a solution when the description gets to long (now it wil hide under the button) */
          }
        </p>
        <button>{action}</button>
      </div>
    </div>
  );

  const GetCard = () => {
    if (items.length > 1)
      return (
        <Stepper
          contents={items.map((item) =>
            card(item.image, item.title, item.description, item.action)
          )}
        />
      );

    const item = items[0];
    return card(item.image, item.title, item.description, item.action);
  };

  return (
    <div style={{ ...style }}>
      <GetCard />
    </div>
  );
}

export default NewsCard;
