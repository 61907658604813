import { MPEGDecoder } from 'mpg123-decoder';

let audioContext;

export function getAudioContext() {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)({
      latencyHint: 'interactive',
      sampleRate: 24000
    });
  }
  return audioContext;
}

let sourceNode;
let rawAudioData = [];
let decoder;
let isPlaying = false;
let isActive = false;
let isPaused = false;

export async function initAudioContext() {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)({
      latencyHint: 'interactive',
      sampleRate: 24000
    });
    decoder = new MPEGDecoder();
    await decoder.ready;
  }
}

export async function processAudioChunk(audioData) {
  await initAudioContext();

  try {
    const uint8Array = new Uint8Array(audioData);
    const decodedData = decoder.decode(uint8Array);

    if (decodedData && decodedData.channelData.length > 0) {
      const leftChannel = decodedData.channelData[0];
      const rightChannel = decodedData.channelData[1];
      const interleavedData = new Float32Array(leftChannel.length * 2);

      for (let i = 0; i < leftChannel.length; i++) {
        interleavedData[i * 2] = leftChannel[i];
        interleavedData[i * 2 + 1] = rightChannel[i];
      }

      rawAudioData.push(interleavedData);

      if (!isPlaying && !isPaused) {
        startPlayback();
      }
    }
  } catch (error) {
    console.error('Error decoding MP3 chunk:', error);
  }
}

function startPlayback() {
  isPlaying = true;
  isActive = true;
  playNextChunk();
}

function playNextChunk() {
  if (rawAudioData.length > 0) {
    const chunk = rawAudioData.shift();

    if (chunk.length === 0) {
      console.error('Empty chunk received');
      playNextChunk();
      return;
    }

    const buffer = audioContext.createBuffer(2, chunk.length / 2, audioContext.sampleRate);
    const leftChannel = buffer.getChannelData(0);
    const rightChannel = buffer.getChannelData(1);

    for (let i = 0; i < chunk.length / 2; i++) {
      leftChannel[i] = chunk[i * 2];
      rightChannel[i] = chunk[i * 2 + 1];
    }

    sourceNode = audioContext.createBufferSource();
    sourceNode.buffer = buffer;
    sourceNode.connect(audioContext.destination);
    sourceNode.onended = () => {
      sourceNode = null;
      if (rawAudioData.length > 0 && !isPaused) {
        playNextChunk();
      } else {
        isPlaying = false;
      }
    };
    sourceNode.start();
  } else {
    isPlaying = false;
    isActive = false;
  }
}

export function pausePlayback() {
  console.log('Pausing playback');
  if (sourceNode) {
    sourceNode.stop();
    isPaused = true;
    isPlaying = false;
  }
}

export function resumePlayback() {
  console.log('Resuming playback');
  if (isPaused) {
    isPaused = false;
    startPlayback();
  }
}

export function isPlayingAudio() {
  return isPlaying;
}

export function isActiveAudio() {
  return isActive;
}

export function resetAudioPlayback() {
  console.log('Resetting audio playback');
  if (sourceNode) {
    sourceNode.stop();
  }
  if (audioContext) {
    audioContext.close();
  }
  audioContext = null;
  sourceNode = null;
  rawAudioData = [];
  isPlaying = false;
  isPaused = false;
  //decoder = null;
}