import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BannerCard from './cards/BannerCard';
import InformationCard from './cards/InformationCard';
import NewsCard from './cards/NewsCard';
import TaskCard from './cards/TaskCard';
import MessageCard from './cards/MessageCard';
import StartCard from './cards/StartCard';
import NotificationCard from './cards/NotificationCard';
import QuickCard from './cards/QuickCard';
import ActionCard from './cards/ActionCard';
import '../assets/styles/Dashboard.css';
import Assistant from './Assistant';
import Activities from './Activities';

function Dashboard({ aiOutput }) {

    const thread_status = useSelector(state => state.main.status);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const content = [
        {
            "component": "BannerCard",
            "props": {
                "title": "EVA, het GenAI platform",
                "description": "Hier kun je mij eenvoudig vragen stellen, hulp krijgen, problemen melden, meldingen beheren, allemaal op één centrale plek.",
                "backgroundImage": "url/to/image",
                "actions": [{ "title": "Ik wil meer weten", "action": "chat", "payload": { "message": "ik wil meer weten over wat je kan doen", "route": "default" } }]
            }
        },
        {
            "component": "ActionCard",
            "props": {
                "actions": [{
                    "title": "Hoe werkt teams?",
                    "action": "chat",
                    "payload": {
                        "message": "Hoe werkt teams?",
                        "route": "default"
                    }
                },
                {
                    "title": "Nieuwe Teams aanvragen",
                    "action": "chat",
                    "payload": {
                        "message": "Ik wil een nieuwe Teams aanvragen",
                        "route": "IT-servicedesk"
                    }
                },
                {
                    "title": "Nieuw account aanvragen",
                    "action": "chat",
                    "payload": {
                        "message": "Ik wil een nieuwe medewerker account aanmaken",
                        "route": "IT-servicedesk"
                    }
                }]
            }
        }];

    //     {
    //         "component": "InformationCard",
    //         "props": {
    //             "items": [{
    //                 "title": "Probleem met inloggen in Microsoft Teams",
    //                 "description": "Laatste actie: Foutmelding ontvangen, screenshot en mobiel nummer gevraagd voor verdere assistentie. 1 uur geleden",
    //                 "actions": [
    //                     { "title": "Weten jullie al wat meer?", "action": "chat", "payload": "Hebben jullie een status update van ticket BM240423 012?" },
    //                     { "title": "Het inloggen werkt weer!", "action": "chat", "payload": "Het inloggen werkt weer! Ik kan weer bezig, gaat om ticket BM240423 012" }]
    //             }],
    //             "span": 1
    //         }
    //     },

    //     {
    //         "component": "InformationCard",
    //         "props": {
    //             "items": [{
    //                 "title": "Feedback Verzocht",
    //                 "description": "We vragen je feedback over je recente ervaring met de servicedesk.",
    //                 "action": "Geef Feedback",
    //                 "link": "/feedback"
    //             }]
    //         }
    //     },
    //     {
    //         "component": "NewsCard",
    //         "props": {
    //             "items": [
    //                 {
    //                     "title": "Gepland Onderhoud",
    //                     "description": "Er is gepland onderhoud op het netwerk op 30 april van 22:00 tot 23:00 uur. Enkele diensten kunnen tijdelijk minder goed bereikbaar zijn.",
    //                     "image": "https://static.wixstatic.com/media/f69c1b_3ca5ae96cccc417eb63be94dafbcb977~mv2.jpg/v1/fill/w_395,h_326,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/GROUP_teampage%20copy%202_edited.jpg",
    //                     "action": "Lees verder"
    //                 }
    //             ]
    //         }
    //     }
    // ];

    // {
    //     "component": "QuickCard",
    // },

    // {
    //     "title": "Graag toestemming om nieuw account aan te maken",
    //     "description": "toestemming vraag",
    //     "message": "Wil je graag weten hoe je met bestanden kan delen met je collega’s of heb je misschien een probleem waar je graag mee geholpen wilt worden? Stel hem hier.",
    //     "actions": [{ "title": "akkoord", "action": "chat", "payload": "ja, ik ben akkoord" },
    //     { "title": "weigeren", "action": "chat", "payload": "nee, ik ben niet akkoord" }],
    //     "type": "info",
    //     "priority": "normal"
    // }


    // {
    //     "component": "TaskCard",
    //     "props": {
    //          "title": "Openstaande Tickets",
    //         "description": "Je hebt 5 openstaande tickets om vandaag te bekijken.",
    //          "action": "Bekijk Tickets",
    //          "link": "/tickets"
    //      }
    //  },
    //   {
    //       "component": "MessageCard",
    //       "props": {
    //           "title": "Feedback Verzocht",
    //           "description": "We vragen je feedback over je recente ervaring met de servicedesk.",
    //           "action": "Geef Feedback",
    //           "link": "/feedback"
    //       }
    //   }

    const getCardComponent = (component) => {
        switch (component) {
            case 'BannerCard':
                return BannerCard;
            case 'InformationCard':
                return InformationCard;
            case 'NewsCard':
                return NewsCard;
            case 'TaskCard':
                return TaskCard;
            case 'MessageCard':
                return MessageCard;
            case 'StartCard':
                return StartCard;
            case 'NotificationCard':
                return NotificationCard;
            case 'QuickCard':
                return QuickCard;
            case 'ActionCard':
                return ActionCard;
            default:
                return null;
        }
    };

    return (
        <div className="dashboard">
            {((isMobile && thread_status == "idle") || !isMobile) && (
                <div className="left-column">
                    {content.map((card, index) => {
                        const CardComponent = getCardComponent(card.component);
                        if (!CardComponent) return null;
                        return (
                            <CardComponent
                                key={index}
                                style={card.props?.span > 0 ? { flex: card.props?.span } : {}}
                                {...card.props}
                            />
                        );
                    })}
                </div>
            )}
            <div className="center-column">
                <Assistant />

            </div>


            {((isMobile && thread_status == "idle") || !isMobile) && (
                <div className="right-column">
                    {<Activities card={true} />}
                    {/*<Actions /> */}
                </div>
            )}
        </div>
    );
}

export default Dashboard;