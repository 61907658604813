// QuickCard.js
import React from 'react';
import Stepper from '../steppers/stepper';
import '../../assets/styles/cards/QuickCard.css';
import { AttachFile, PhoneInTalkOutlined, PhotoCamera, StartOutlined } from '@mui/icons-material';

function QuickCard({ style }) {

  return (
    <div className="card quick-card">
      <div className="quick-buttons-container">
        <button><StartOutlined /></button>
        <button><PhotoCamera /></button>
        <button><AttachFile /></button>
        <button><PhoneInTalkOutlined /></button>
      </div>
    </div>
  );
}

export default QuickCard;