// InformationCard.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Stepper from '../steppers/stepper';
import '../../assets/styles/cards/InformationCard.css';
import { sendMessage } from '../Actions';
import Modal from '../Modal'; // Importeer uw Modal component
import Chatbot from '../Chatbot'; // Importeer uw Chatbot component

function InformationCard({ style, items }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!items) return <div></div>;

  const handleAction = (action) => {
    if (!action) return;

    if (action.action == "chat") {
      let message = { "type": "user", "message": action.payload, "thread_id": null, "route": null }
      setIsModalOpen(true);
      dispatch(sendMessage(message));
    }
  };

  const card = (image, title, description, actions) => (
    <div className="information-card-container">
      <div className="content">
        <h2>{title}</h2>
        <p>
          {
            description /* needs a solution when the description gets to long (now it wil hide under the button) */
          }
        </p>
        <div class="information-card-actions">
          {(actions || []).map((action) => (
            <button key={action.title} onClick={() => { handleAction(action) }}>{action.title}</button>
          ))}
        </div>
      </div>
    </div>
  );

  const GetCard = () => {
    if (items.length > 1)
      return (
        <Stepper
          contents={items.map((item) =>
            card(item.image, item.title, item.description, item.actions)
          )}
        />
      );

    const item = items[0];
    return card(item.image, item.title, item.description, item.actions);
  };

  return (
    <React.Fragment>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Chatbot modal={true} />
      </Modal>
      <div className="card information-card" style={{ ...style }}>
        <GetCard />
      </div>
    </React.Fragment>

  );
}

export default InformationCard;
