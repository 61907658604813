import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getAudioContext, processAudioChunk, pausePlayback, resetAudioPlayback, isPlayingAudio, resumePlayback, isActiveAudio } from './AudioUtils';
import { useSelector } from 'react-redux';

const AudioCapture = ({ isListening, onEnd, onTranscribing }) => {
  const [isSessionActive, setIsSessionActive] = useState(false);
  const transcript = useRef('');
  const [sessionTranscript, setSessionTranscript] = useState('');
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const socketRef = useRef(null);
  const audioChunksRef = useRef([]);
  const silenceStartRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const streamRef = useRef(null);
  const latestTranscriptRef = useRef('');
  const audioPlayerRef = useRef(null);
  const latestSpeechRef = useRef(null);
  const isStartedRef = useRef(false);
  const hasSendAudioRef = useRef(false);
  const interruptionTimeoutRef = useRef(null);
  const audioContext = getAudioContext();
  const threadStatus = useSelector((state) => state.main.status);
  const [threadStatusLocal, setThreadStatusLocal] = useState('idle');

  useEffect(() => {
    if (threadStatus === 'idle' && threadStatusLocal === 'active') {
      setThreadStatusLocal('idle');
      endSession();
    }
  }, [threadStatus, threadStatusLocal]);

  const initializeWebSocket = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      socketRef.current = new WebSocket('ws://localhost:8000/ws');

      socketRef.current.onopen = () => {
        console.log('WebSocket connected');
        resolve();
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received data:', data);
        if (data.text) {
          handleTranscription(data.text);
        }
        if (data.speech) {
          latestSpeechRef.current = data.speech;
          playAIResponse(data.speech);
        }
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
      };

      socketRef.current.onclose = (event) => {
        console.log('WebSocket closed:', event);
      };
    });
  }, []);

  const startSession = useCallback(async () => {
    if (streamRef.current) {
      console.log('Session already active, not starting a new one');
      return;
    }

    console.log('Starting new session');
    transcript.current = '';
    setSessionTranscript('');
    latestSpeechRef.current = null;

    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Audio stream obtained');

      if (audioContextRef.current) {
        await audioContextRef.current.close();
      }
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(streamRef.current);
      source.connect(analyserRef.current);
      console.log('Audio context and analyser set up');

      await initializeWebSocket();
      isStartedRef.current = false;
      await startRecording();
      setIsSessionActive(true);
    } catch (error) {
      console.error('Error starting session:', error);
    }
  }, [initializeWebSocket]);

  const startRecording = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;
      }

      audioChunksRef.current = [];

      if (!streamRef.current) {
        console.error('No audio stream available');
        reject(new Error('No audio stream available'));
        return;
      }

      console.log('Starting MediaRecorder');
      mediaRecorderRef.current = new MediaRecorder(streamRef.current);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
          //console.log('Audio data collected, size:', event.data.size);
        } else {
          console.log('No audio data collected');
        }
      };

      mediaRecorderRef.current.onstart = () => {
        console.log('MediaRecorder started');
        resolve();
      };

      mediaRecorderRef.current.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        reject(event.error);
      };

      mediaRecorderRef.current.start(100);
    });
  }, []);

  const sendAudioToServer = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN && audioChunksRef.current.length > 0) {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          console.log('Sending audio, size:', audioBlob.size);
          socketRef.current.send(audioBlob);
          audioChunksRef.current = []; // Clear the chunks after sending
          startRecording(); // Restart recording
        } else {
          console.log('Unable to send audio:', {
            socketOpen: socketRef.current && socketRef.current.readyState === WebSocket.OPEN,
            audioChunksLength: audioChunksRef.current.length
          });
        }
      };
    }
  }, [startRecording]);

  const endSession = useCallback(() => {
    if (!mediaRecorderRef.current) return;

    console.log("isStartedRef.current", isStartedRef.current);
    if (!isStartedRef.current) return;

    console.log('Ending session');
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    setIsSessionActive(false);
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (sessionTimeoutRef.current) {
      clearTimeout(sessionTimeoutRef.current);
      sessionTimeoutRef.current = null;
    }
    if (interruptionTimeoutRef.current) {
      clearTimeout(interruptionTimeoutRef.current);
      interruptionTimeoutRef.current = null;
    }

    analyserRef.current = null;
    audioChunksRef.current = [];

    if (onEnd) {
      console.log('Sending onEnd with transcript:', transcript.current);
      onEnd(transcript.current);
    }

    transcript.current = '';
    latestTranscriptRef.current = '';
    latestSpeechRef.current = null;

    startSession();
  }, [onEnd, startSession]);

  const handleTranscription = useCallback((text) => {
    setSessionTranscript(text);
    latestTranscriptRef.current = text;
    transcript.current = transcript.current + " " + text;

    if (onTranscribing && text !== '') {
      text = text.replace(/\*/g, '').trim();
      onTranscribing(text);
    }

    // Check if the transcription contains at least three words
    const wordCount = text.split(' ').filter(word => word.length > 0).length;
    if (wordCount >= 3 && isActiveAudio()) {
      resetAudioPlayback();
      sendAudioToServer(); // Send the new user input to the server
    } else if (isActiveAudio()) {
      resumePlayback();
    }
  }, [onTranscribing, sendAudioToServer]);

  const detectSilence = useCallback(() => {
    if (!analyserRef.current) return;

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    analyserRef.current.getByteFrequencyData(dataArray);

    const average = dataArray.reduce((a, b) => a + b) / bufferLength;

    console.log('Audio level:', average);

    if (average > 10) {
      silenceStartRef.current = null;
      hasSendAudioRef.current = false;
      isStartedRef.current = true;
      if (isActiveAudio() && isPlayingAudio()) {
        pausePlayback();
        // Start a timeout to check if it's a real interruption
        if (interruptionTimeoutRef.current) clearTimeout(interruptionTimeoutRef.current);
        interruptionTimeoutRef.current = setTimeout(() => {
          const wordCount = latestTranscriptRef.current.split(' ').filter(word => word.length > 0).length;
          if (wordCount >= 3) {
            console.log("Interruption detected, sending new audio to server");
            sendAudioToServer();
          } else {
            console.log("False interruption, resuming playback");
            resumePlayback();
          }
        }, 1000); // Wait for 1 second to determine if it's a real interruption
      }

      if (sessionTimeoutRef.current) {
        clearTimeout(sessionTimeoutRef.current);
        sessionTimeoutRef.current = null;
      }
    } else if (!silenceStartRef.current && isStartedRef.current) {
      silenceStartRef.current = Date.now();
      hasSendAudioRef.current = false;
      if (!sessionTimeoutRef.current) {
        sessionTimeoutRef.current = setTimeout(() => {
          console.log("Session timeout reached");
          endSession();
        }, 1000);
      }
    } else if (Date.now() - silenceStartRef.current > 100 && isStartedRef.current && !hasSendAudioRef.current) {
      hasSendAudioRef.current = true;
      sendAudioToServer();
    }

    if (isSessionActive) {
      requestAnimationFrame(detectSilence);
    }
  }, [isSessionActive, sendAudioToServer, endSession]);

  const playAIResponse = useCallback((speech) => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current = null;
    }
    
    audioPlayerRef.current = new Audio(speech);
    audioPlayerRef.current.play();
    
    audioPlayerRef.current.onended = () => {
      console.log("AI response playback ended");
      audioPlayerRef.current = null;
      // Restart listening for user input
      startRecording();
    };
  }, [startRecording]);

  useEffect(() => {
    if (isListening) {
      startSession();
    } else {
      endSession();
    }

    return () => {
      if (isListening) {
        endSession();
      }
    };
  }, [isListening, startSession, endSession]);

  useEffect(() => {
    if (isSessionActive) {
      requestAnimationFrame(detectSilence);
    }

    return () => {
      if (sessionTimeoutRef.current) {
        clearTimeout(sessionTimeoutRef.current);
      }
      if (interruptionTimeoutRef.current) {
        clearTimeout(interruptionTimeoutRef.current);
      }
    };
  }, [isSessionActive, detectSilence]);

  return null;
};

export default AudioCapture;