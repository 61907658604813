import React, { useState, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  Link,
} from 'react-router-dom';
import Chatbot from './components/Chatbot';
import Tickets from './components/Tickets';
import Activities from './components/Activities';
import Management from './components/management/Management';
import {
  useIsAuthenticated,
  useMsalAuthentication,
  useMsal,
  useAccount,
} from '@azure/msal-react';
import {
  InteractionType,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import './assets/styles/App.css';
import Header from './components/Header';
import { connection } from 'websocket';
import { use } from 'marked';
import Menu from './components/Menu';
import Dashboard from './components/Dashboard';
import './assets/styles/Customer.css';
import LoginScreen from './loginScreen';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AudioInitializer from './components/AudioInitializer';


function App() {
  const lastUsedAccount = localStorage.getItem('lastUsedAccount');
  const request = {
    loginHint: lastUsedAccount,
    scopes: ['User.Read'],
  }
  const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request);
  const connectionStatus = useSelector((state) => state.main.connectionStatus);
  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0] || null);
  const isAuthenticated = useIsAuthenticated();
  const [isAcquiringToken, setIsAcquiringToken] = useState(false);
  const [needsSignIn, setNeedsSignIn] = useState(false);
  const [gotTokens, setGotTokens] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const authenticate = async () => {
      await doAuth();
    };
    if (!isAuthenticated) {
      if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/'
      ) {
        sessionStorage.setItem('preLoginUrl', window.location.pathname);
        authenticate();
      }
    } else {
      const preLoginUrl = sessionStorage.getItem('preLoginUrl');
      if (preLoginUrl) {
        sessionStorage.removeItem('preLoginUrl');
        navigate(preLoginUrl);
        authenticate();
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (account) {
      localStorage.setItem('lastUsedAccount', account.username);
      if (!gotTokens && !isAcquiringToken) {
        acquireTokensAndDispatch();
      }
    }
  }, [account]);

  useEffect(() => {
    const initializeMsal = async () => {
      if (!instance) {
        await instance.initialize();
      }
    };

    const authenticate = async () => {
      await doAuth();
    };

    if (inProgress === "none") {
      initializeMsal().then(authenticate);
    }
  }, [inProgress]);

  useEffect(() => {
    if (error) {
      console.error('Error: ', error);
      redirectToLogin();
    }
  }, [error, result]);

  useEffect(() => {
    const initializeMsal = async () => {
      if (!instance) {
        await instance.initialize();
      }
    };

    const authenticate = async () => {
      await doAuth();
    };

    if (connectionStatus === 'invalid_token') {
      console.log('Invalid token received');
      initializeMsal().then(authenticate);
    }
  }, [connectionStatus]);

  const acquireTokenSilently = async (scopes) => {
    try {
      if (!instance) {
        //throw new Error("MSAL instance is not initialized");
        await instance.initialize();
      }
      const response = await instance.acquireTokenSilent({
        scopes: [scopes],
        account: account
      });
      return response.accessToken;
    } catch (error) {
      console.error("Error acquiring token silently:", error);
      redirectToLogin();
      return null;
    }
  };

  const acquireTokensAndDispatch = async () => {
    try {
      const apiToken = await acquireTokenSilently('api://7ae91f63-d4bb-4bff-980f-bd0c0fead9e8/Api.read');
      const graphToken = await acquireTokenSilently('https://graph.microsoft.com/User.Read');
      if (apiToken && graphToken) {
        dispatch({
          type: 'SET_MSAL',
          payload: {
            api: apiToken,
            graph: graphToken
          }
        });
        setGotTokens(true);
      }
    } catch (error) {
      console.error("Error acquiring tokens:", error);
    }
  };

  const redirectToLogin = () => {
    if (inProgress !== "none") return;
    return instance.loginRedirect({
      scopes: ['api://7ae91f63-d4bb-4bff-980f-bd0c0fead9e8/Api.read'],
      extraScopesToConsent: ['https://graph.microsoft.com/User.Read'],
    });
  };

  const doAuth = async (force = false) => {
    if (!instance) {
      await instance.initialize();
    }

    if (instance && inProgress === 'none' && account && !force) {
      setIsAcquiringToken(true);
      setNeedsSignIn(false);
      try {
        acquireTokensAndDispatch();
        setIsAcquiringToken(false);
      } catch (error) {
        console.error("Error during token acquisition:", error);
        setIsAcquiringToken(false);
      }
    } else if (instance && inProgress === 'none' && (account || force || lastUsedAccount)) {
      redirectToLogin();
    } else if (!account) {
      setIsAcquiringToken(false);
      setNeedsSignIn(true);
    }
  };

  // Create a custom theme
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
      background: {
        default: '#121212',
        paper: '#1d1d1d',
      },
      text: {
        primary: '#ffffff',
        secondary: '#b0bec5',
      },
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <AudioInitializer />
      <CssBaseline />
      {(isAuthenticated && account) ? (
        isAcquiringToken ? (
          <LoginScreen inProgress={true} />
        ) : (
          <div className="main">
            <Header account={account} />
            <div className='route-container'>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/gesprek/:thread_id?" element={<Chatbot />} />
                <Route path="/meldingen/:ticket_id?" element={<Tickets />} />
                <Route path="/activiteiten/:activityId?" element={<Activities />} />
                <Route path="/management/*" element={<Management />} />
              </Routes>
            </div>
          </div>
        )
      ) : (
        <LoginScreen inProgress={isAcquiringToken} needsSignIn={needsSignIn} />
      )}
    </ThemeProvider>
  );
}

export default App;