// MessageCard.js
import React from 'react';

function MessageCard({ style, title, description, action }) {
    return (
        <div className="card message-card" style={{ ...style }}>
            <h2>{title}</h2>
            <p>{description}</p>
            <button>{action}</button>
        </div>
    );
}

export default MessageCard;